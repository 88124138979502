<template>
  <v-card class="accounting-document d-flex flex-column" style="height: 100%;">
    <v-toolbar color="grey darken-3" dark elevation="1" style="flex: 0" height="52">
      <v-toolbar-title class="document-toolbar-title">
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        right
        app
        width="400"
        v-model="document_state.show"
        style="z-index: 9999"
        color="grey lighten-4"
    >
      <v-card tile flat class="transparent">
        <v-card-text class="pa-0">
          <div style="display: flex; align-items: center" class="py-2 pl-3 pr-1 grey darken-2 white--text">
            <div style="flex: 1; font-weight: 500;">Перелік помилок заповнення</div>
            <div style="flex: 0 0 30px">
              <v-btn icon small @click.stop="document_state.show = false" class=" grey darken-2">
                <v-icon color="secondary">mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="px-2 pt-2 pb-2">
          <div>
            <div style="display: flex; margin-bottom: 5px; padding: 3px; border-radius: 5px;"
                 class="elevation-3 white"
                 v-for="(item, item_idx) in document_state.errors"
                 :key="`error-${item_idx}`"
            >
              <div class="py-1 px-2">
                <div class="mb-1">
                  <v-chip small color="success" label class="mr-1" style="height: 20px !important;">
                    {{ item.table }}
                  </v-chip>
                  <v-chip small color="grey lighten-3" label class="mr-1" style="height: 20px !important;"
                          v-if="item.row_num">
                    {{ `№ рядка: ${item.row_num}` }}
                  </v-chip>
                </div>
                <div style="color: #2d2d2d; font-weight: 400; font-size: .76rem">
                  {{ item.text }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
    <v-dialog v-model="show_tax_dialog" persistent width="500">
      <v-card>
        <v-card-text class="pa-0 grey lighten-3">
          <div class="py-3 px-6 text-center black--text" style="font-size: 1rem">
            Налаштування обліку ПДВ
          </div>
        </v-card-text>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" class="py-1">
              <v-switch class="mt-0" color="success" hide-details
                        :label="tax_enable_local ? 'Враховувати ПДВ' : 'Не враховувати ПДВ'"
                        v-model="tax_enable_local"/>
            </v-col>
            <v-col cols="12" class="py-1">
              <v-switch class="mt-0" color="success" hide-details
                        :label="tax_in_sum_local ? 'ПДВ у тому числі' : 'ПДВ зверху'"
                        v-model="tax_in_sum_local"/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small depressed color="secondary" @click="closeTaxModal(true)">ОК</v-btn>
          <v-btn small depressed @click="closeTaxModal(false)">Закрити</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text class="pt-2 pb-0"
                 style="flex: 1; display: flex; flex-direction: column; overflow: hidden;"
    >
      <div class="conducted-box d-flex">
        <v-alert
            dense
            text
            class="mb-0 py-1"
            :color="conducted ? 'success' : 'secondary'"
            :type="conducted ? 'success' : 'warning'"
            style="flex: 1"
        >
          {{ conducted ? 'Документ проведений' : 'Документ не проведений' }}
        </v-alert>
      </div>
      <div class="document-header" style="flex: 0">
        <div style="display: flex; flex-wrap: nowrap;">
          <div>
            <div class="document-header-row px-0 pt-2 pb-2">
              <div style="flex: 0 0 170px; margin-right: 16px">
                <date_input v-model="date" label="Дата: " :time="true" required :disabled="false"
                            @userChange="onDateChange" id="date"/>
              </div>
              <div style="flex: 0 0 110px; margin-right: 16px">
                <string_input v-model="number" label="Номер: " :disabled="true"/>
              </div>
              <div style="flex: 0 0 110px; margin-right: 16px">
                <select_input
                    v-model="currency_id"
                    :computed-action="'SELECT_CURRENCY_LIST_ELEMENTS'"
                    :computed-getter="'get_currency_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    label="Валюта: "
                    :select-button-object="{
                                name: 'CURRENCY_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список валют',
                                element_title: 'Валюта',
                                show: true
                              }"
                    @selectChange="onDateChange"
                />
              </div>
              <div style="flex: 0 0 310px; margin-right: 16px;">
                <select_input
                    v-model="operation"
                    :import-getter="{ name: 'tax_bill_document_outcome_operation' }"
                    :input-value-as-value="false"
                    :disabled="without_accounting"
                    clearable
                    label="Операція: "
                    @selectChange="onOperationChange"
                />
              </div>
              <div style="flex: 0 0 140px; margin-right: 16px; align-self: flex-end">
                <v-btn block small depressed height="31" @click="show_tax_dialog = true">Ціна вкл. ПДВ</v-btn>
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-2">
              <div style="flex: 0 0 296px; margin-right: 16px">
                <select_input
                    v-model="contractor_id"
                    :computed-action="'SELECT_CONTRACTOR_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :action-props="{currency_id: currency_id, date: date}"
                    :show-additional-field="true"
                    label="Контрагент"
                    :show-select-on-focus="false"
                    :select-button-object="{
                                name: 'CONTRACTOR_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список контрагентів',
                                element_title: 'Контрагент',
                                show: true,
                                action_props: {currency_id: currency_id, date: date},
                                showAdditionalField: true
                              }"
                    @selectChange="onDateChange"
                />
              </div>
              <div style="flex: 0 0 436px; margin-right: 16px">
                <select_input
                    v-model="contract_id"
                    :computed-action="'SELECT_CONTRACTOR_CONTRACT_LIST_ELEMENTS'"
                    :computed-getter="'get_contractor_contract_list_select'"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="false"
                    :row_num="0"
                    :owner_id="contractor_id"
                    label="Договір"
                    :show-select-on-focus="false"
                    :sync-action-props="true"
                    :select-button-object="{
                                name: 'CONTRACTOR_CONTRACT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список договорів контрагента',
                                element_title: 'Договір контрагента',
                                show: true,
                                action_props: {owner_id: contractor_id},
                                sync_action_props: true
                              }"
                    @selectChange="onDateChange"
                />
              </div>
            </div>
            <div class="document-header-row px-0 pt-1 pb-2">
              <div style="flex: 0 0 296px; margin-right: 16px">
                <select_input
                    v-model="document_reason_type"
                    :import-getter="{ name: 'tax_bill_outcome_document_type' }"
                    :input-value-as-value="false"
                    label="Вид документа підстави"
                    clearable
                    :required="!is_legacy"
                    @selectChange="documentTypeChange"
                />
              </div>
              <div style="flex: 0 0 436px; margin-right: 16px">
                <select_input
                    v-if="document_reason_type"
                    label="Документ підстава"
                    v-model="document_reason_id"
                    :computed-action="documents_select[document_reason_type].action_select"
                    :computed-getter="documents_select[document_reason_type].getter_select"
                    :input-value-as-value="false"
                    :item-search="'text'"
                    :show-on-focus="true"
                    :action-props="{date_to: date, contractor_id: contractor_id}"
                    :select-button-object="{
                                name: document_reason_type,
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: documents_select[document_reason_type].list_title,
                                element_title: documents_select[document_reason_type].element_title,
                                show: true,
                                document: true,
                                full_screen: true,
                                action_props: {date_to: date, contractor_id: contractor_id}
                              }"
                    @selectChange="documentChange"
                />
                <string_input label="Документ підстава" v-else disabled/>
              </div>
            </div>
          </div>
          <v-spacer></v-spacer>
          <div class="grey lighten-4 py-2 px-3 document-info-box">
            <div class="document-info-box-element">
              <div class="info-box-element-title">Підсумки</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">без ПДВ:</div>
                  <div class="element-item-value">000<span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title">ПДВ:</div>
                  <div class="element-item-value">111<span>грн.</span></div>
                </div>
              </div>
            </div>
            <div class="document-info-box-element">
              <div class="info-box-element-title">Стан розрахунків</div>
              <div class="info-box-element-divider"></div>
              <div class="info-box-element-item-wrapper">
                <div class="info-box-element-item">
                  <div class="element-item-title">з ПДВ:</div>
                  <div class="element-item-value">222<span>грн.</span></div>
                </div>
                <div class="info-box-element-item">
                  <div class="element-item-title"></div>
                  <div class="element-item-value"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="document-body pt-0 pb-1" style="flex: 1; display: flex; flex-direction: column; overflow: hidden;">
        <div class="px-4 pt-2 pb-0" style="flex: 1; display: flex; height: 100%;">
          <v-row style="height: 100%;">
            <v-col cols="12" sm="12" md="12" class="grey lighten-3 pb-0" style="overflow: hidden; height: 100%;">
              <v-tabs class="custom-tabs without" color="success" style="height: 100%; ">
                <v-tab>
                  Реквізити документа
                </v-tab>
                <v-tab>
                  Товари
                </v-tab>
                <v-tab>
                  Послуги
                </v-tab>
                <v-tab>
                  ОС
                </v-tab>

                <v-tab-item class="pa-0" style="height: 100%">
                  <div style="width: 100%; height: 97%; background-color: white" class="py-1 px-3 mt-2">
                    <div class="block-divider ml-1">
                      Бухгалтерський облік
                    </div>
                    <div class="d-flex flex-nowrap pl-3">
                      <div style="flex: 0 0 406px; margin-right: 16px">
                        <select_input
                            v-model="tax_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            label="Рахунок ПДВ"
                            :show-select-on-focus="false"
                            :action-filter="'@field=type@;@start-with=S@64'"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@64',
                              show: true
                            }"
                            clearable
                            @selectChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 484px; margin-right: 16px">
                        <select_input
                            v-model="tax_correction_account_id"
                            :computed-action="'SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS'"
                            :computed-getter="'get_chart_of_accounts_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :row_num="0"
                            label="Рахунок ПДВ (не підтвердж.)"
                            :show-select-on-focus="false"
                            :action-filter="'@field=type@;@start-with=S@64'"
                            :select-button-object="{
                              name: 'CHART_OF_ACCOUNTS_ELEMENTS',
                              group: '',
                              hierarchy: false,
                              only_groups: false,
                              title: 'План рахунків',
                              element_title: 'Рахунок',
                              action_filter: '@field=code@;@start-with=S@64',
                              show: true
                            }"
                            clearable
                            @selectChange="onDateChange"
                        />
                      </div>
                    </div>
                    <div class="block-divider ml-1">
                      Податкова накладна
                    </div>
                    <div class="d-flex flex-nowrap pb-3 pl-3">
                      <div style="flex: 0 0 190px; margin-right: 16px">
                        <v-checkbox v-model="include_to_erpn"
                                    hide-details
                                    color="success"
                                    label="Включено до ЄРПН"
                                    class="document-check-box"
                                    @change="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px">
                        <v-checkbox v-model="consolidated_tax_bill"
                                    hide-details
                                    color="success"
                                    label="Зведена"
                                    class="document-check-box"
                                    @change="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px">
                        <select_input
                            v-model="consolidate_type"
                            :import-getter="{ name: 'tax_bill_consolidate_type' }"
                            :input-value-as-value="false"
                            clearable
                            :required="false"
                            :disabled="!consolidated_tax_bill"
                            label="Тип зведеної наклад.: "
                            @selectChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 270px; margin-right: 16px">
                        <select_input
                            v-model="signed_person_id"
                            :computed-action="'SELECT_PERSONAL_LIST_ELEMENTS'"
                            :computed-getter="'get_personal_list_select'"
                            :input-value-as-value="false"
                            :item-search="'text'"
                            :show-on-focus="false"
                            :show-select-on-focus="false"
                            :required="true"
                            label="Виписав ПН: "
                            clearable
                            :select-button-object="{
                                                        name: 'PERSONAL_ELEMENTS',
                                                        group: '',
                                                        hierarchy: false,
                                                        only_groups: false,
                                                        title: 'Список працівників',
                                                        element_title: 'Працівник',
                                                        show: true,
                                                        clearable: true
                                                      }"
                            @selectChange="onDateChange"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-nowrap pb-3 pl-3">
                      <div style="flex: 0 0 190px; margin-right: 16px">
                        <v-checkbox v-model="included_in_the_clarifying"
                                    hide-details
                                    color="success"
                                    label="Включ. в уточ. розрах." class="document-check-box"
                                    @change="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px;">
                        <date_input v-model="clarifying_date" label="Період уточ./корег."
                                    :required="false"
                                    @userChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px">
                        <select_input
                            v-model="special_mode"
                            :import-getter="{ name: 'tax_bill_spec_mode' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Спец.режим оподаткування: "
                            @selectChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 270px; margin-right: 16px">
                        <select_input
                            v-model="not_issued_type"
                            :import-getter="{ name: 'tax_bill_not_issue_type' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Причина невидач. покупцеві: "
                            @selectChange="onDateChange"
                        />
                      </div>
                    </div>
                    <div class="d-flex flex-nowrap pl-3">
                      <div style="flex: 0 0 406px; margin-right: 16px">
                        <select_input
                            v-model="contract_type"
                            :import-getter="{ name: 'tax_bill_contract_type' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Вид договору: "
                            @selectChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 200px; margin-right: 16px;">
                        <select_input
                            v-model="calculation_form"
                            :import-getter="{ name: 'tax_bill_calculated_form' }"
                            :input-value-as-value="false"
                            :disabled="without_accounting"
                            clearable
                            label="Форма розрахунків: "
                            @selectChange="onDateChange"
                        />
                      </div>
                      <div style="flex: 0 0 270px; margin-right: 16px">
                        <string_input
                            v-model="condition_of_sale"
                            label="Умова продажу"
                            :required="false"
                        />
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item>
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addGoodsTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearGoodsTableDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № ПН
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Од. виміру
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Вид коду
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Код
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Кількість
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Ціна
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            ПДВ %
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Всього
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in goods"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ goods.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteGoodsTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 70px">
                          <number_input
                              :row_num="charge.row_num"
                              v-model="charge.table_number"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                              :computed-getter="'get_nomenclature_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              :action-props="{is_service: false}"
                              small
                              transparent
                              :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: 'NOMENCLATURE_GROUP',
                                hierarchy: true,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: false},
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterGoodsNomenclatureSelect"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.unit_type_id"
                              :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                              :computed-getter="'get_unit_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :item-text="'additional_field_1'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              :row_num="charge.row_num"
                              v-model="charge.code_type"
                              :import-getter="{ name: 'tax_bill_table_code_type' }"
                              :input-value-as-value="false"
                              :disabled="without_accounting"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <string_input
                              :row_num="charge.row_num"
                              v-model="charge.code"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="true"
                                        @userChange="tableGoodsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.price" v-decimal transparent
                                        small field="price"
                                        :required="true"
                                        @userChange="tableGoodsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum"
                                        v-decimal transparent
                                        small field="sum"
                                        :required="true"
                                        @userChange="tableGoodsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@pdv'"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                              transparent
                              @selectChange="tableGoodsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.tax" v-decimal transparent
                                        small field="tax"
                                        :required="false"
                                        @userChange="tableGoodsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 710px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.goods.count | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.goods.sum | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px" >
                            {{ footer_total.goods.tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.goods.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addServicesTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearServicesTableDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № ПН
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Од. виміру
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Вид коду
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Код
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Кількість
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Ціна
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            ПДВ %
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Всього
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in services"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ services.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteServicesTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 70px">
                          <number_input
                              :row_num="charge.row_num"
                              v-model="charge.table_number"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_NOMENCLATURE_LIST_ELEMENTS'"
                              :computed-getter="'get_nomenclature_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              :action-props="{is_service: true}"
                              small
                              transparent
                              :select-button-object="{
                                name: 'NOMENCLATURE_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список номенклатури',
                                element_title: 'Номенклатура',
                                action_props: {is_service: true},
                                show: true
                              }"
                              :required="true"
                              @selectChange="afterServicesNomenclatureSelect"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.unit_type_id"
                              :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                              :computed-getter="'get_unit_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :item-text="'additional_field_1'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              :row_num="charge.row_num"
                              v-model="charge.code_type"
                              :import-getter="{ name: 'tax_bill_table_code_type' }"
                              :input-value-as-value="false"
                              :disabled="without_accounting"
                              clearable
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <string_input
                              :row_num="charge.row_num"
                              v-model="charge.code"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="true"
                                        @userChange="tableServicesColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.price" v-decimal transparent
                                        small field="price"
                                        :required="true"
                                        @userChange="tableServicesColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum" v-decimal transparent
                                        small field="sum"
                                        :required="true"
                                        @userChange="tableServicesColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@pdv'"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                              transparent
                              @selectChange="tableServicesColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.tax" v-decimal transparent
                                        small field="tax"
                                        :required="false"
                                        @userChange="tableServicesColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 710px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services.count | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services.sum | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px" >
                            {{ footer_total.services.tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.services.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item class="pa-0" style="height: 100%">
                  <div class="command-panel mt-2">
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="success mr-2" :disabled="!document_state.header_ready"
                           @click.stop="addFixedAssetsTableRow"
                    >
                      <v-icon small left>mdi-plus</v-icon>
                      Додати рядок
                    </v-btn>
                    <v-btn small
                           :dark="document_state.header_ready"
                           class="error lighten-1" :disabled="!document_state.header_ready"
                           @click.stop="clearFixedAssetsTableDialog"
                    >
                      <v-icon small left>mdi-close</v-icon>
                      Очистити таблицю
                    </v-btn>
                  </div>
                  <div class="document-table with-command mt-2">
                    <div class="document-table-body">
                      <div class="document-table-header">
                        <div class="document-table-row">
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № п/п
                          </div>
                          <div class="document-table-col" style="flex: 0 0 70px">
                            № ПН
                          </div>
                          <div class="document-table-col" style="flex: 0 0 270px">
                            Номенклатура
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Од. виміру
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Вид коду
                          </div>
                          <div class="document-table-col" style="flex: 0 0 100px">
                            Код
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Кількість
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Ціна
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px">
                            ПДВ %
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Сума ПДВ
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px">
                            Всього
                          </div>
                        </div>
                      </div>
                      <div class="document-table-row"
                           v-for="(charge) in fixed_assets"
                           :key="`charge-${charge.row_num}`"
                      >
                        <div class="document-table-col" style="flex: 0 0 70px; display: flex">
                          <div style="flex: 1; padding-left: 4px">
                            {{ fixed_assets.indexOf(charge) + 1 }}
                          </div>
                          <div style="flex:  0 0 32px">
                            <v-btn icon small class="grey lighten-3" @click="deleteFixedAssetsTableRow(charge)">
                              <v-icon small color="grey darken-2">mdi-delete</v-icon>
                            </v-btn>
                          </div>
                        </div>
                        <div class="document-table-col" style="flex: 0 0 70px">
                          <number_input
                              :row_num="charge.row_num"
                              v-model="charge.table_number"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 270px">
                          <select_input
                              v-model="charge.nomenclature_id"
                              :computed-action="'SELECT_FIXED_ASSETS_LIST_ELEMENTS'"
                              :computed-getter="'get_fixed_assets_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'FIXED_ASSETS_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список основний засобів',
                                element_title: 'Основні засоби',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              v-model="charge.unit_type_id"
                              :computed-action="'SELECT_UNIT_LIST_ELEMENTS'"
                              :computed-getter="'get_unit_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :item-text="'additional_field_1'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :show-search-on-focus="true"
                              small
                              transparent
                              :select-button-object="{
                                name: 'UNIT_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список одиниць виміру',
                                element_title: 'Одиниця виміру',
                                show: true
                              }"
                              :required="true"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <select_input
                              :row_num="charge.row_num"
                              v-model="charge.code_type"
                              :import-getter="{ name: 'tax_bill_table_code_type' }"
                              :input-value-as-value="false"
                              :disabled="without_accounting"
                              clearable
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 100px">
                          <string_input
                              :row_num="charge.row_num"
                              v-model="charge.code"
                              small transparent
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.count" v-decimal transparent
                                        small field="count"
                                        :required="true"
                                        @userChange="tableFixedAssetsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.price" v-decimal transparent
                                        small field="price"
                                        :required="true"
                                        @userChange="tableFixedAssetsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.sum" v-decimal transparent
                                        small field="sum"
                                        :required="true"
                                        @userChange="tableFixedAssetsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 160px">
                          <select_input
                              v-model="charge.tax_id"
                              :computed-action="'SELECT_TAXES_LIST_ELEMENTS'"
                              :computed-getter="'get_taxes_list_select'"
                              :input-value-as-value="false"
                              :item-search="'text'"
                              :show-on-focus="false"
                              :row_num="charge.row_num"
                              :show-select-on-focus="false"
                              :action-filter="'@field=type@;@start-with=S@pdv'"
                              small
                              :select-button-object="{
                                name: 'TAXES_ELEMENTS',
                                group: '',
                                hierarchy: false,
                                only_groups: false,
                                title: 'Список податків/внесків',
                                element_title: 'Податок/внесок',
                                show: true,
                                action_filter: '@field=tax_group@;@start-with=S@pdv'
                              }"
                              transparent
                              @selectChange="tableFixedAssetsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.tax" v-decimal transparent
                                        small field="tax"
                                        :required="false"
                                        @userChange="tableFixedAssetsColChange"
                          />
                        </div>
                        <div class="document-table-col" style="flex: 0 0 120px">
                          <number_input :row_num="charge.row_num" v-model="charge.result" v-decimal transparent
                                        small disabled
                                        :required="false"
                          />
                        </div>
                      </div>
                      <div class="document-table-footer mt-2">
                        <div class="document-table-row">
                          <div class="document-table-col"
                               :style="`flex: 0 0 710px`"
                               style="text-align: right; padding-right: 6px">
                            Всього:
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.fixed_assets.count | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.fixed_assets.sum | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 160px; padding-left: 10px">
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px" >
                            {{ footer_total.fixed_assets.tax | formatToFixed | formatNumber }}
                          </div>
                          <div class="document-table-col" style="flex: 0 0 120px; padding-left: 10px">
                            {{ footer_total.fixed_assets.result | formatToFixed | formatNumber }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-tab-item>
              </v-tabs>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-card-text>
    <v-card-actions style="flex: 0">
      <template>
        <document_print v-model="show_print"
                        :doc_type="'GET_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS'"
                        :doc_id="itemId"
                        :doc_form="'v1'"
                        v-if="!isNew"
        ></document_print>
        <v-spacer></v-spacer>
        <v-btn depressed color="grey lighten-3" @click="create_xml">
          <v-icon color="secondary">mdi-xml</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="!isNew" @click="show_print = true">
          <v-icon color="teal">mdi-printer</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="!conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(true)">
          <v-icon color="success">mdi-file-check-outline</v-icon>
        </v-btn>
        <v-btn depressed color="grey lighten-3" v-if="conducted" :disabled="isNew" :loading="getModalLoading"
               @click="doConducted(false)">
          <v-icon color="error lighten-1">mdi-file-cancel-outline</v-icon>
        </v-btn>
        <v-btn depressed text color="secondary darken-1" @click="crud_item" :loading="getModalLoading"
               class="button-accept">
          <v-icon left>mdi-content-save</v-icon>
          Провести та закрити
        </v-btn>
        <v-btn depressed text tile color="grey" @click="closeModal">
          <v-icon left>mdi-close</v-icon>
          Закрити
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
  UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
  REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
  CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS
} from "@/store/actions/accounting/list";
import {mapGetters} from 'vuex'
import {QUESTION_SHOW} from "@/store/actions/question";
import taxBillOutcomeAPI from "@/utils/axios/accounting/documents/tax_bill_outcome"
import bankAccountIncomeAPI from "@/utils/axios/accounting/documents/bank_income"
import actWorkPerformedOutcome from "@/utils/axios/accounting/documents/act_work_performed_outcome"
import invoiceWArehouseOutcome from "@/utils/axios/accounting/documents/invoice_warehouse_outcome"
import billOutcome from "@/utils/axios/accounting/documents/bill_outcome"
import {maxBy} from "lodash";
import chartOfAccountAPI from "@/utils/axios/accounting/chart_of_account";
import {round_number} from "@/utils/icons";
import {ALERT_SHOW} from "@/store/actions/alert";
import {subconto_type_select_setting, getDocumentsObject} from '@/utils/accounting'

import ModalAccountingComponentMixin from "@/mixins/modal_account_component";

const modalDeleteId = 'tax_bill_outcome_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Document_TaxBillOutcome",
  mixins: [ModalAccountingComponentMixin],
  components: {
    select_input: () => import("@/components/accounting/input/document_select_input"),
    string_input: () => import("@/components/accounting/input/document_string_input"),
    date_input: () => import("@/components/accounting/input/document_date_input"),
    number_input: () => import("@/components/accounting/input/document_number_input"),
    document_print: () => import("@/components/DocumentPrintModalWrapper")
  },
  data() {
    return {
      show_tax_dialog: false,
      subconto_type_select_setting,
      documents_select: {},
      footer_total: {
        goods: {count: 0, sum: 0, tax: 0, result: 0},
        services: {count: 0, sum: 0, tax: 0, result: 0},
        fixed_assets: {count: 0, sum: 0, tax: 0, result: 0},
      },
      without_accounting: this.item.without_accounting || false,
      operation: this.item.operation || null,
      currency_id: this.item.currency_id || null,
      contractor_id: this.item.contractor_id || null,
      contract_id: this.item.contract_id || null,
      document_reason_type: this.item.document_reason_type || null,
      document_reason_id: this.item.document_reason_id || null,
      contract_type: this.item.contract_type || null,
      special_mode: this.item.special_mode || null,
      condition_of_sale: this.item.condition_of_sale || null,
      not_issued_type: this.item.not_issued_type || null,
      calculation_form: this.item.calculation_form || null,
      include_to_erpn: this.item.include_to_erpn || false,
      consolidated_tax_bill: this.item.consolidated_tax_bill || false,
      consolidate_type: this.item.consolidate_type || null,
      included_in_the_clarifying: this.item.included_in_the_clarifying || false,
      clarifying_date: this.item.clarifying_date || null,
      clarifying_number: this.item.clarifying_number || null,
      tax_account_id: this.item.tax_account_id || null,
      tax_correction_account_id: this.item.tax_correction_account_id || null,
      complaint: this.item.complaint || null,
      signed_person_id: this.item.signed_person_id || null,
      tax_in_sum: this.item.tax_in_sum || false,
      tax_enable: this.item.tax_enable || false,
      tax_in_sum_local: this.item.tax_in_sum || false,
      tax_enable_local: this.item.tax_enable || false,
      is_legacy: this.item.is_legacy || false,
      flat_id: this.item.flat_id || null,
      month: this.item.month || null,
      goods: [],
      services: [],
      fixed_assets: []
    }
  },
  methods: {
    getDocumentsObject,

    getChartsOfAccounts() {
      chartOfAccountAPI.get_all()
          .then(response => response.data)
          .then(data => {
            this.charts = data

            if (this.isNew) {
              this.onOperationChange({value: this.operation})
                const tax_account_id = this.charts.find(c => c.code === '6412')
                this.tax_account_id = (tax_account_id || {}).id

              if (this.item.basic_create_id) {
                if (this.item.basic_create_type === 'DOCUMENT_BANK_INCOME') {
                  bankAccountIncomeAPI.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_CASH_ORDER_INCOME') {
                  bankAccountIncomeAPI.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_ACT_WORK_PERFORMED_OUTCOME') {
                  actWorkPerformedOutcome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        data.table.forEach(row => {
                          this.services.push(
                              {
                                table_number: this.getTableRowNumber(),
                                nomenclature_id: row.nomenclature_id,
                                unit_type_id: row.unit_type_id,
                                code_type: 'CODE_DKPP',
                                code: row.code,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('services'),
                              }
                          )
                        })
                        this.getTotals()
                        this.checkDocument(false, true)
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_INVOICE_WAREHOUSE_OUTCOME') {
                  invoiceWArehouseOutcome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        data.services.forEach(row => {
                          this.services.push(
                              {
                                table_number: this.getTableRowNumber(),
                                nomenclature_id: row.nomenclature_id,
                                unit_type_id: row.unit_type_id,
                                code_type: 'CODE_DKPP',
                                code: row.code,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('services'),
                              }
                          )
                        })
                        data.goods.forEach(row => {
                          this.goods.push(
                              {
                                table_number: this.getTableRowNumber(),
                                nomenclature_id: row.nomenclature_id,
                                unit_type_id: row.unit_type_id,
                                code_type: 'CODE_UKTZ',
                                code: row.code,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('goods'),
                              }
                          )
                        })
                        this.getTotals()
                        this.checkDocument(false, true)
                      })
                }
                if (this.item.basic_create_type === 'DOCUMENT_BILL_OUTCOME') {
                  billOutcome.get_document_by_id(this.item.basic_create_id)
                      .then(response => response.data)
                      .then(data => {
                        this.contractor_id = data.contractor_id
                        this.contract_id = data.contract_id
                        this.document_reason_type = this.item.basic_create_type
                        this.document_reason_id = this.item.basic_create_id

                        data.services.forEach(row => {
                          this.services.push(
                              {
                                table_number: this.getTableRowNumber(),
                                nomenclature_id: row.nomenclature_id,
                                unit_type_id: row.unit_type_id,
                                code_type: 'CODE_DKPP',
                                code: row.code,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('services'),
                              }
                          )
                        })
                        data.goods.forEach(row => {
                          this.goods.push(
                              {
                                table_number: this.getTableRowNumber(),
                                nomenclature_id: row.nomenclature_id,
                                unit_type_id: row.unit_type_id,
                                code_type: 'CODE_UKTZ',
                                code: row.code,
                                tax_id: row.tax_id,
                                count: row.count,
                                price: row.price,
                                sum: row.sum,
                                result: row.result,
                                row_num: this.getNewRowNum('goods'),
                              }
                          )
                        })
                        this.getTotals()
                        this.checkDocument(false, true)
                      })
                }
              }
            }
          })
    },
    onDateChange() {
      this.$nextTick(() => {
        this.checkDocument(false, false)
      })
    },
    closeTaxModal(success = false) {
      if (success) {
        this.tax_in_sum = this.tax_in_sum_local
        this.tax_enable = this.tax_enable_local

        this.goods.forEach(item => {
          this.calcRow(item)
        })
        this.services.forEach(item => {
          this.calcRow(item)
        })
        this.fixed_assets.forEach(item => {
          this.calcRow(item)
        })
      }

      this.show_tax_dialog = false
    },
    getTotals() {
      this.footer_total.goods = this.goods.reduce((acc, item) => {
        acc.count += item.count || 0
        acc.sum += item.sum || 0
        acc.tax += item.tax || 0
        acc.result += item.result || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
      this.footer_total.services = this.services.reduce((acc, item) => {
        acc.count += item.count || 0
        acc.sum += item.sum || 0
        acc.tax += item.tax || 0
        acc.result += item.result || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
      this.footer_total.fixed_assets = this.fixed_assets.reduce((acc, item) => {
        acc.count += item.count || 0
        acc.sum += item.sum || 0
        acc.tax += item.tax || 0
        acc.result += item.result || 0
        return acc
      }, {count: 0, sum: 0, tax: 0, result: 0})
    },
    getNewRowNum(table) {
      const max_row_item = maxBy(this[table], 'row_num')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.row_num + 1
      }
      return max_row_num
    },
    getNewTableRowNum(table) {
      const max_row_item = maxBy(this[table], 'table_number')

      let max_row_num = 1
      if (max_row_item) {
        max_row_num = max_row_item.table_number + 1
      }
      return max_row_num
    },
    getStartInfo() {
      this.getChartsOfAccounts()
    },
    checkDocumentTable(filters, table_name, table) {
      const table_charge_error = []
      const items = this[table].filter(item => {
        let r = false
        Object.keys(filters).forEach(key => {
          if (item[key] === undefined || item[key] === null || item[key] === 0 || item[key] === '') {
            r = true
          }
        })
        return r
      })

      items.forEach(item => {
        const idx = this[table].indexOf(item)
        const row_key = `row_${idx}`

        Object.keys(filters).forEach(key => {
          const filter_row = filters[key]
          let skip = false

          if (filter_row.condition) {
            if (item[filter_row.condition] !== null && item[filter_row.condition] !== undefined && item[filter_row.condition] !== 0) {
              skip = item[key] !== null && item[key] !== undefined && item[key] !== 0
            } else {
              skip = true
            }
          } else {
            skip = item[key] !== null && item[key] !== undefined && item[key] !== 0 && item[key] !== '';
          }

          if (!skip) {
            if (!table_charge_error[row_key]) {
              table_charge_error[row_key] = {row_num: idx + 1, text: filters[key].text}
            } else {
              table_charge_error[row_key].text += `// ${filters[key].text}`
            }
          }
        })
      })

      Object.keys(table_charge_error).forEach(key => {
        this.document_state.errors.push(
            {table: table_name, row_num: table_charge_error[key].row_num, text: table_charge_error[key].text}
        )
      })
    },
    checkDocument(show=false, table=true) {
      this.document_state.show = false
      this.document_state.errors = []

      const header = {
        'date': {id: 'date', text: 'Не заповнена дата документа'},
        'operation': {id: 'operation', text: 'Не заповнена операція'},
        'currency_id': {id: 'currency_id', text: 'Не заповнена одиниця виміру'},
        'contractor_id': {id: 'contractor_id', text: 'Не заповнений контрагент'},
        'contract_id': {id: 'contract_id', text: 'Не заповнений договір контрагент'},
        'contract_type': {id: 'contract_type', text: 'Не заповнений вид договору'},
        'special_mode': {id: 'special_mode', text: 'Спец. режим оподаткування'},
        'not_issued_type': {id: 'not_issued_type', text: 'Причина не видачі ПН'},
        'calculation_form': {id: 'calculation_form', text: 'Форма розрахунків'},
        'tax_account_id': {id: 'tax_account_id', text: 'Рахунок ПДВ'},
        'tax_correction_account_id': {id: 'tax_correction_account_id', text: 'Рахунок ПДВ (непідтверджене)'},
        'signed_person_id': {id: 'signed_person_id', text: 'Особоа, котра виписала ПН'},
      }

      if (!this.is_legacy) {
        header['document_reason_type'] = {id: 'document_reason_type', text: 'Не заповнений вид документа підстави'}
        header['document_reason_id'] ={id: 'document_reason_id', text: 'Не заповнений документа підстави'}
      }

      const goods_table = {
        'table_number': {id: 'table_number', table: 'Товари', text: 'Не заповнений номер рядка ПН'},
        'nomenclature_id': {id: 'nomenclature_id', table: 'Товари', text: 'Не заповнена номенклатура'},
        'tax_id': {id: 'tax_id', table: 'Товари', text: 'Не заповнена ставка ПДВ'},
        'unit_type_id': {id: 'unit_type_id', table: 'Товари', text: 'Не заповнена одиниця виміру'},
        'code_type': {id: 'code_type', table: 'Товари', text: 'Не заповнений вид коду'},
        'code': {id: 'code', table: 'Товари', text: 'Не заповнений код'},
        'count': {id: 'count', table: 'Товари', text: 'Не заповнена кількість'},
        'price': {id: 'price', table: 'Товари', text: 'Не заповнена ціна'},
        'sum': {id: 'sum', table: 'Товари', text: 'Не заповнена сума'},
      }

      const service_table = {
        'table_number': {id: 'table_number', table: 'Послуги', text: 'Не заповнений номер рядка ПН'},
        'nomenclature_id': {id: 'nomenclature_id', table: 'Послуги', text: 'Не заповнена номенклатура'},
        'tax_id': {id: 'tax_id', table: 'Послуги', text: 'Не заповнена ставка ПДВ'},
        'unit_type_id': {id: 'unit_type_id', table: 'Послуги', text: 'Не заповнена одиниця виміру'},
        'code_type': {id: 'code_type', table: 'Послуги', text: 'Не заповнений вид коду'},
        'code': {id: 'code', table: 'Послуги', text: 'Не заповнений код'},
        'count': {id: 'count', table: 'Послуги', text: 'Не заповнена кількість'},
        'price': {id: 'price', table: 'Послуги', text: 'Не заповнена ціна'},
        'sum': {id: 'sum', table: 'Послуги', text: 'Не заповнена сума'},
      }

      const fixed_assets_table = {
        'table_number': {id: 'table_number', table: 'Послуги', text: 'Не заповнений номер рядка ПН'},
        'fixed_asset_id': {id: 'nomenclature_id', table: 'Послуги', text: 'Не заповнений ОС'},
        'tax_id': {id: 'tax_id', table: 'Послуги', text: 'Не заповнена ставка ПДВ'},
        'unit_type_id': {id: 'unit_type_id', table: 'Послуги', text: 'Не заповнена одиниця виміру'},
        'code_type': {id: 'code_type', table: 'Послуги', text: 'Не заповнений вид коду'},
        'code': {id: 'code', table: 'Послуги', text: 'Не заповнений код'},
        'count': {id: 'count', table: 'Послуги', text: 'Не заповнена кількість'},
        'price': {id: 'price', table: 'Послуги', text: 'Не заповнена ціна'},
        'sum': {id: 'sum', table: 'Послуги', text: 'Не заповнена сума'},
      }

      if (this.included_in_the_clarifying) {
        header['clarifying_date'] = {id: 'clarifying_date', text: 'Не заповнений період уточнення'}
      }


      Object.keys(header).forEach(key => {
        if (!this[key]) {
          this.document_state.errors.push(
              {table: 'Шапка', row_num: 0, text: header[key].text}
          )
        }
      })

      this.document_state.header_ready = this.document_state.errors.length === 0;

      if (table) {
        this.checkDocumentTable(goods_table, 'Таблиця', 'goods')
        this.checkDocumentTable(service_table, 'Послуги', 'services')
        this.checkDocumentTable(fixed_assets_table, 'Основні засоби', 'fixed_assets')
      }

      if (this.document_state.errors.length) {
        this.document_state.ready = false
        this.document_state.show = show
      } else {
        this.document_state.ready = true
        this.document_state.show = false
      }
    },
    doConducted(payload) {
      if (this.isNew) return

      this.$store.dispatch(CONDUCTED_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS,
          {document_id: this.itemId, conducted: payload}
      )
          .then(data => {
            if (data) {
              const text = payload ? 'Документ успішно проведений' : 'Відміна проведення успішно виконана'
              const color = payload ? 'success' : 'error'
              this.conducted = payload
              this.$store.commit(ALERT_SHOW, {text, color})
            }
          })
    },

    clearPersonData(payload) {
      let local_data = payload || {}

      this.tab = 0
      this.without_accounting = local_data.without_accounting || false
      this.conducted = local_data.conducted || false
      this.date = local_data.date || null
      this.number = local_data.number || null
      this.itemId = local_data.id || null
      this.operation = local_data.operation || null
      this.currency_id = local_data.currency_id || null
      this.contractor_id = local_data.contractor_id || null
      this.contract_id = local_data.contract_id || null
      this.document_reason_type = local_data.document_reason_type || null
      this.document_reason_id = local_data.document_reason_id || null
      this.contract_type = local_data.contract_type || null
      this.special_mode = local_data.special_mode || null
      this.condition_of_sale = local_data.condition_of_sale || null
      this.not_issued_type = local_data.not_issued_type || null
      this.calculation_form = local_data.calculation_form || null
      this.include_to_erpn = local_data.include_to_erpn || false
      this.consolidated_tax_bill = local_data.consolidated_tax_bill || false
      this.consolidate_type = local_data.consolidate_type || null
      this.included_in_the_clarifying = local_data.included_in_the_clarifying || false
      this.clarifying_date = local_data.clarifying_date || null
      this.clarifying_number = local_data.clarifying_number || null
      this.tax_account_id = local_data.tax_account_id || null
      this.tax_correction_account_id = local_data.tax_correction_account_id || null
      this.complaint = local_data.complaint || null
      this.signed_person_id = local_data.signed_person_id || null
      this.tax_in_sum = local_data.tax_in_sum || false
      this.tax_enable = local_data.tax_enable || false
      this.flat_id = local_data.flat_id || null
      this.is_legacy = local_data.is_legacy || false
      this.month = local_data.month || null

      this.goods = []
      this.services = []
      this.fixed_assets = []
    },
    clearTable(table) {
      this[table] = []
      this.getTotals()
    },
    clearGoodsTableDialog() {
      const payload = {
        text: `Очистити таблицю товарів?`,
        accept_button: true,
        id: 'clear_goods_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearServicesTableDialog() {
      const payload = {
        text: `Очистити таблицю послуг?`,
        accept_button: true,
        id: 'clear_services_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    clearFixedAssetsTableDialog() {
      const payload = {
        text: `Очистити таблицю ОС?`,
        accept_button: true,
        id: 'clear_fixed_assets_table'
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    get_account_details(chart_id) {
      const chart = this.charts.find(c => c.id === chart_id) || {}
      const details = {
        account_detail_1: {id: null, type: null},
        account_detail_2: {id: null, type: null},
        account_detail_3: {id: null, type: null},
      }
      if (chart.id) {
        const ch_details = chart.details || []

        const obj_1 = ch_details.find(i => i.order === 1)
        const obj_2 = ch_details.find(i => i.order === 2)
        const obj_3 = ch_details.find(i => i.order === 3)

        if (obj_1) {
          details.account_detail_1.type = obj_1.detail_type
          details.account_detail_1.id = obj_1.id
        }
        if (obj_2) {
          details.account_detail_2.type = obj_2.detail_type
          details.account_detail_2.id = obj_2.id
        }
        if (obj_3) {
          details.account_detail_3.type = obj_3.detail_type
          details.account_detail_3.id = obj_3.id
        }
      }
      return details
    },
    getTableRowNumber() {
      const goods_row = this.getNewTableRowNum('goods')
      const services_row = this.getNewTableRowNum('services')
      const fixed_assets_row = this.getNewTableRowNum('services')

      return Math.max(goods_row, services_row, fixed_assets_row)
    },
    addGoodsTableRow() {
      this.goods.push(
          {
            table_number: this.getTableRowNumber(),
            nomenclature_id: null,
            unit_type_id: null,
            code_type: 'CODE_UKTZ',
            code: '',
            tax_id: null,
            count: 0,
            price: 0,
            sum: 0,
            result: 0,
            row_num: this.getNewRowNum('goods'),
          }
      )
    },
    addServicesTableRow() {
      this.services.push(
          {
            table_number: this.getTableRowNumber(),
            nomenclature_id: null,
            unit_type_id: null,
            code_type: 'CODE_DKPP',
            code: '',
            tax_id: null,
            count: 0,
            price: 0,
            sum: 0,
            result: 0,
            row_num: this.getNewRowNum('services'),
          }
      )
    },
    addFixedAssetsTableRow() {
      this.fixed_assets.push(
          {
            table_number: this.getTableRowNumber(),
            fixed_asset_id: null,
            unit_type_id: null,
            code_type: 'CODE_UKTZ',
            code: '',
            tax_id: null,
            count: 0,
            price: 0,
            sum: 0,
            result: 0,
            row_num: this.getNewRowNum('fixed_assets'),
          }
      )
    },

    getTaxTariff(tax_id) {
      if (!tax_id) return null
      const tariffs = {}
      const pdv_without = this.settings?.pdv_without?.value || 0
      const pdv_0 = this.settings?.pdv_0?.value || 0
      const pdv_5 = this.settings?.pdv_5?.value || 0
      const pdv_7 = this.settings?.pdv_7?.value || 0
      const pdv_9 = this.settings?.pdv_9?.value || 0
      const pdv_20 = this.settings?.pdv_20?.value || 0

      tariffs[pdv_without] = null
      tariffs[pdv_0] = 0
      tariffs[pdv_5] = 5
      tariffs[pdv_7] = 7
      tariffs[pdv_9] = 9
      tariffs[pdv_20] = 20

      return tariffs[tax_id] || null
    },

    deleteGoodsTableRow(payload) {
      this.goods.splice(this.goods.indexOf(payload), 1)
      this.getTotals()
    },
    deleteServicesTableRow(payload) {
      this.services.splice(this.services.indexOf(payload), 1)
      this.getTotals()
    },
    deleteFixedAssetsTableRow(payload) {
      this.fixed_assets.splice(this.fixed_assets.indexOf(payload), 1)
      this.getTotals()
    },

    goodsTableColChange(payload) {
      const row = this.goods.find(i => i.row_num === payload.row_num)
      if (!row) return

      this.calcRow(row, payload.field)
    },
    servicesTableColChange(payload) {
      const row = this.services.find(i => i.row_num === payload.row_num)
      if (!row) return

      this.calcRow(row, payload.field)
    },

    afterGoodsNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service, unit_type_id, code_uktz
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
        unit_type_id = +(val.additional_field_2) || null
        code_uktz = val.text_field_1
      } else {
        pdv = val.tax_type
        is_service = val.is_service
        unit_type_id = val.unit_type
        code_uktz = val.code_uktz
      }

      if (val.row_num) {
        const row = this.goods.find(item => item.row_num === val.row_num)
        if (row) {
          if (is_service) {
            this.$nextTick(() => {
              row.nomenclature_id = null
            })
            return;
          }
          row.tax_id = pdv
          row.unit_type_id = unit_type_id
          row.code = code_uktz
          this.goodsTableColChange(val)
        }
      }
    },
    afterServicesNomenclatureSelect(payload) {
      const val = payload || {}
      let pdv, is_service, unit_type_id, code_dkpp
      if ("value" in val) {
        pdv = +(val.type || '0')
        is_service = val.additional_field_1 === "True"
        unit_type_id = +(val.additional_field_2) || null
        code_dkpp = val.text_field_2
      } else {
        pdv = val.tax_type
        is_service = val.is_service
        unit_type_id = val.unit_type
        code_dkpp = val.code_dkpp
      }

      if (val.row_num) {
        const row = this.services.find(item => item.row_num === val.row_num)
        if (row) {
          if (is_service) {
            this.$nextTick(() => {
              row.nomenclature_id = null
            })
            return;
          }
          row.tax_id = pdv
          row.unit_type_id = unit_type_id
          row.code = code_dkpp
          this.servicesTableColChange(val)
        }
      }
    },

    onOperationChange(payload) {
      const value = (payload || {}).value || null
      const tax_correction_account_6432 = this.charts.find(c => c.code === '6432')
      const tax_correction_account_6435 = this.charts.find(c => c.code === '6435')
      const tax_correction_account_92 = this.charts.find(c => c.code === '92')

      this.$nextTick(() => {
        if (!value) {
          this.special_mode = null
          this.included_in_the_clarifying = false
          this.tax_correction_account_id = tax_correction_account_6432
          this.not_issued_type = 'CODE_00'
          this.consolidated_tax_bill = false
          this.consolidate_type = 'CODE_0'
          this.contract_type = 'DPK'
          this.calculation_form = 'bank'
          this.tax_enable = true
        } else {
          this.special_mode = 'not_use'
          this.included_in_the_clarifying = false
          if (value === 'taxable_operation') {
            this.tax_correction_account_id = (tax_correction_account_6432 || {}).id
          }
          if (value === 'tax_exempted') {
            this.tax_correction_account_id = (tax_correction_account_6432 || {}).id
          }
          if (value === 'conditional_sale') {
            this.tax_correction_account_id = (tax_correction_account_6435 || {}).id
          }
          if (value === 'conditional_sale_write_off_fixed_assets') {
            this.tax_correction_account_id = (tax_correction_account_92 || {}).id
          }
          this.consolidate_type = 'CODE_0'
          this.contract_type = 'DPK'
          this.not_issued_type = 'CODE_00'
          this.consolidated_tax_bill = false
          this.calculation_form = 'bank'
          this.tax_enable = true
        }
      this.checkDocument(false, false)
      })
    },
    documentTypeChange() {
      this.document_reason_id = null
    },
    documentChange(payload) {
      const document_id = (payload || {}).id || (payload || {}).value
      if (document_id) {
        taxBillOutcomeAPI.get_document_sum({
          document_type: this.document_reason_type,
          document_id: document_id
        })
            .then(response => response.data)
            .then(() => {
              this.checkDocument(false, false)
              this.getTotals()
            })
            .finally(() => {
              this.checkDocument(false, false)
              this.getTotals()
            })
      }
    },

    tableGoodsColChange(payload) {
      this.$nextTick(() => {
        const row = this.goods.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
        this.getTotals()
      })
    },
    tableServicesColChange(payload) {
      this.$nextTick(() => {
        const row = this.services.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
        this.getTotals()
      })
    },
    tableFixedAssetsColChange(payload) {
      this.$nextTick(() => {
        const row = this.services.find(i => i.row_num === payload.row_num)
        if (!row) return

        this.calcRow(row, payload.field)
        this.getTotals()
      })
    },
    create_xml() {
      taxBillOutcomeAPI.create_xml(this.itemId)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = response.headers['x-blob-file-name'] || 'generated.zip'
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
    },

    calcRow(payload, col = '') {
      if (!payload) return
      const tax_tariff = this.getTaxTariff(payload.tax_id)

      if (col === 'sum') {
        payload.price = round_number(payload.sum / payload.count, 4)
      }
      if (this.tax_enable) {
        if (col === 'tax') {
          if (this.tax_in_sum) {
            payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
            payload.result = payload.sum
          } else {
            payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
            payload.result = this.round_number(payload.sum + payload.tax, 2)
          }
          return;
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      payload.sum = round_number(payload.count * payload.price, 2)

      if (this.tax_enable) {
        if (this.tax_in_sum) {
          payload.tax = this.round_number(payload.sum / (100 / tax_tariff + 1), 2)
          payload.result = payload.sum
        } else {
          payload.tax = this.round_number(payload.sum * tax_tariff / 100, 2)
          payload.result = this.round_number(payload.sum + payload.tax, 2)
        }
      } else {
        payload.tax = 0
        payload.result = payload.sum
      }

      this.getTotals()
    },

    crud_item() {
      this.checkDocument()
      if (!this.document_state.ready) {
        this.document_state.show = true
        return
      }

      const payload = this.get_payload_for_crud()

      if (this.isNew) {
        this.$store.dispatch(CREATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      } else {
        this.$store.dispatch(UPDATE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    get_payload_for_crud() {
      const payload = {
        without_accounting: this.without_accounting,
        date: this.date,
        operation: this.operation,
        currency_id: this.currency_id,
        contractor_id: this.contractor_id,
        contract_id: this.contract_id,
        document_reason_type: this.document_reason_type,
        document_reason_id: this.document_reason_id,
        special_mode: this.special_mode,
        condition_of_sale: this.condition_of_sale,
        not_issued_type: this.not_issued_type,
        calculation_form: this.calculation_form,
        contract_type: this.contract_type,
        include_to_erpn: this.include_to_erpn,
        consolidated_tax_bill: this.consolidated_tax_bill,
        consolidate_type: this.consolidate_type,
        included_in_the_clarifying: this.included_in_the_clarifying,
        clarifying_date: this.clarifying_date,
        clarifying_number: this.clarifying_number,
        tax_account_id: this.tax_account_id,
        tax_correction_account_id: this.tax_correction_account_id,
        complaint: this.complaint,
        signed_person_id: this.signed_person_id,
        tax_in_sum: this.tax_in_sum,
        tax_enable: this.tax_enable,
        month: this.month,
        goods: this.goods,
        services: this.services,
        fixed_assets: this.fixed_assets
      }

      if (!this.isNew) {
        payload.id = this.itemId
      }

      return payload
    },
    load_select_data() {
      // return Promise.all([
      //   this.$store.dispatch(SELECT_CHART_OF_ACCOUNTS_LIST_ELEMENTS),
      // ])
    },
    fetch_data_by_id() {
      if (this.isNew) {
        this.getChartsOfAccounts()
        return
      }

      taxBillOutcomeAPI.get_document_by_id(this.itemId)
          .then(response => response.data)
          .then(data => {
            this.without_accounting = data.without_accounting
            this.date = data.date
            this.conducted = data.conducted
            this.number = data.number
            this.operation = data.operation
            this.currency_id = data.currency_id
            this.contractor_id = data.contractor_id
            this.contract_id = data.contract_id
            this.document_reason_type = data.document_reason_type
            this.document_reason_id = data.document_reason_id
            this.contract_type = data.contract_type
            this.special_mode = data.special_mode
            this.condition_of_sale = data.condition_of_sale
            this.not_issued_type = data.not_issued_type
            this.calculation_form = data.calculation_form
            this.include_to_erpn = data.include_to_erpn
            this.consolidated_tax_bill = data.consolidated_tax_bill
            this.consolidate_type = data.consolidate_type
            this.included_in_the_clarifying = data.included_in_the_clarifying
            this.clarifying_date = data.clarifying_date
            this.clarifying_number = data.clarifying_number
            this.tax_account_id = data.tax_account_id
            this.tax_correction_account_id = data.tax_correction_account_id
            this.complaint = data.complaint
            this.signed_person_id = data.signed_person_id
            this.tax_in_sum = data.tax_in_sum || false
            this.tax_enable = data.tax_enable || false
            this.flat_id = data.flat_id || null
            this.is_legacy = data.is_legacy || false
            this.month = data.month


            this.goods = data.goods.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.services = data.services.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })
            this.fixed_assets = data.fixed_assets.map((i, idx) => {
              return Object.assign({}, i, {row_num: idx + 1})
            })

            this.getTotals()
            this.getChartsOfAccounts()
          })
          .finally(() => {
            this.$nextTick(() => {
              this.checkDocument(true, true)
            })
          })
    },

    closeModal() {
      this.$emit('closeModal')
      this.clearPersonData(this.item)

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення договору по ЦПХ № ${this.number} (${this.person_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    watch_item() {
      this.watcher_item = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.clearPersonData(payload)
              this.fetch_data_by_id()
              this.checkDocument(false, false)
            }
          }
      )
    },
    watch_dialog() {
      this.watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher_item) {
                  this.watcher_item()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_DOCUMENT_TAX_BILL_OUTCOME_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal()
                        }
                      })
                }
              }
              if (payload.id === 'clear_goods_table') {
                if (payload.answer) {
                  this.clearTable('goods')
                }
              }
              if (payload.id === 'clear_services_table') {
                if (payload.answer) {
                  this.clearTable('services')
                }
              }
              if (payload.id === 'clear_fixed_assets_table') {
                if (payload.answer) {
                  this.clearTable('fixed_assets')
                }
              }
            }
          }
      )
    }
  },
  computed: {
    ...mapGetters({
      settings: 'getAccountingGeneralSettings'
    }),

    footer_total_width() {
      if (this.show_detail_1 && this.show_detail_2 && this.show_detail_3) {
        return 1230
      }
      if (this.show_detail_1 && this.show_detail_2 && !this.show_detail_3) {
        return 1080
      }
      if (this.show_detail_1 && !this.show_detail_2 && !this.show_detail_3) {
        return 930
      }
      return 780
    },
    document_header_ready() {
      return this.document_state.header_ready
    }
  },
  created() {
    this.documents_select = this.getDocumentsObject()
  }
}
</script>
